import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import CustomTable, { ColumnConfig } from "../../../components/CustomTable";
import Header from '../../dashboard/components/Header';
import BackButton from '../../../components/BackButton';
import { fetchUserSessionLogs } from '../../../utils/services/apiService';

// Update the interface to match the actual API response
interface UserSessionLog {
    id: number;
    latitude: string | null;
    longitude: string | null;
    activity: string;
    ip: string;
    user: {
        user_full_name: string;
    };
}

const UserLogs: React.FC = () => {
    const [data, setData] = useState<UserSessionLog[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const loadUserSessionLogs = async () => {
            try {
                const response = await fetchUserSessionLogs();
                setData(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching user session logs:', error);
                setLoading(false);
            }
        };

        loadUserSessionLogs();
    }, []);

    const columns: ColumnConfig[] = [
        { 
            field: 'user_full_name', 
            headerName: 'User Full Name',
            width: 200,
            renderCell: (params: any) => (
                <span>
                    {params.row?.user?.user_full_name || '-'}
                </span>
            ),
        },
        { 
            field: 'activity', 
            headerName: 'Activity',
            width: 150,
            renderCell: (params: any) => (
                <span>
                    {params.row?.activity || '-'}
                </span>
            ),
        },
        { 
            field: 'latitude', 
            headerName: 'Latitude',
            width: 150,
            renderCell: (params: any) => (
                <span>
                    {params.row?.latitude || 'N/A'}
                </span>
            ),
        },
        { 
            field: 'longitude', 
            headerName: 'Longitude',
            width: 150,
            renderCell: (params: any) => (
                <span>
                    {params.row?.longitude || 'N/A'}
                </span>
            ),
        },
        { 
            field: 'ip', 
            headerName: 'IP Address',
            width: 150,
            renderCell: (params: any) => (
                <span>
                    {params.row?.ip || '-'}
                </span>
            ),
        },
    ];

    return (
        <div className="screen">
            <Header />
            <div className="table">
                <div className="table-header-container flex justify-between items-center">
                    <Box className="flex items-center gap-2">
                        <BackButton redirectTo="/logs" />
                        <Typography variant="h6" className="breadcrumb">
                            User Session Logs
                        </Typography>
                    </Box>
                </div>

                <div className="custom-table">
                    <CustomTable
                        editMode="row"
                        data={data}
                        columns={columns}
                        loading={loading}
                        getRowId={(row: any) => row.id}
                    />
                </div>
            </div>
        </div>
    );
};

export default UserLogs;