import axios, { AxiosInstance, AxiosResponse } from "axios";
import { API_LINK } from "../../values/constants";
import {
    setGlobal403Popup,
} from "../../components/ErrorContext";
import { create } from "domain";
// import { useGeoContext } from "../../components/GeoContext";

export const api: AxiosInstance = axios.create({
    baseURL: API_LINK,
});

// Request Interceptor: Add Authorization token and Geolocation data
api.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem("accessToken") || "";
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");
    // Add Authorization token to headers
    config.headers.Authorization = token ? `Bearer ${token}` : "";

    // Add Geolocation (Latitude and Longitude) to headers if available
    if (latitude && longitude) {
        config.headers["X-Latitude"] = latitude;
        config.headers["X-Longitude"] = longitude;
    }
    return config;
});


// Response Interceptor: Handle 403 errors
api.interceptors.response.use(
    (response) => response,
    (error) => {
        // Check for 403 Forbidden Error
        if (error.response && error.response.data?.status === 403) {
            setGlobal403Popup(true); 
        }
        return Promise.reject(error);
    }
);


// Function to send POST requests
async function createPostRequest(
    relativeUrl: string,
    data: any,
    contentType: string = "application/json",
    config: any = {}
): Promise<AxiosResponse> {
    try {
        const token = sessionStorage.getItem("accessToken") || "";
        const headers = {
            Accept: "application/json",
            "Content-Type": contentType,
            Authorization: `Bearer ${token}`,
        };
        const response: AxiosResponse = await api.post(relativeUrl, data, {
            headers,
            ...config,
        });
        return response;
    } catch (error: any) {
        return error.response;
    }
}

// Function to send PUT requests
// async function createPutRequest(
//     relativeUrl: string,
//     data?: any
// ): Promise<AxiosResponse> {
//     try {
//         const headers = {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//         };
//         let response: AxiosResponse;
//         if (data) {
//             response = await api.put(relativeUrl, data, {
//                 headers,
//             });
//         } else {
//             response = await api.put(relativeUrl, {
//                 headers,
//             });
//         }
//         return response;
//     } catch (error: any) {
//         return error.response;
//     }
// }

// Function to send GET requests
async function createGetRequest(
    relativeUrl: string,
    params?: Record<string, any>
): Promise<AxiosResponse> {
    try {
        const headers = {
            Accept: "application/json",
        };
        const response: AxiosResponse = await api.get(relativeUrl, {
            headers,
            params,
        });
        return response;
    } catch (error: any) {
        return error.response;
    }
}

// Trigger OTP User
export const triggerOtpUser = (data: {
    user_mobile_number: string;
    type: string;
    otp?: string;
}) => {
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");

    // Add geolocation to the body if available
    const requestBody = {
        ...data,
        ...(latitude && longitude && { latitude, longitude }),
    };

    return api.post("/auth/triggerOtpUser", requestBody);
};

//2. --------------------------------Set User Password----------------------------
export const setUserPassword = (data: {
    user_mobile_number: string;
    user_password: string;
}) => {
    return createPostRequest("/auth/SetUserPassword", data);
};

// User Sign-In Using Password
export const userSignInUsingPassword = (data: {
    deviceType: string;
    user_password: string;
    user_mobile_number?: string;
    user_email?: string;
}) => {
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");

    // Add geolocation to the body if available
    const requestBody = {
        ...data,
        ...(latitude && longitude && { latitude, longitude }),
    };

    return api.post("/auth/UserSignInUsingPassword", requestBody);
};

//4. ---------------------------Fetch Purchase Orders----------------------------
export const fetchPurchaseOrders = (data?: { user_id?: number }) => {
    return createPostRequest("/manageQr/fetchPurchaseOrders", data || {});
};
//5. ---------------------------Upload Purchase Order----------------------------
export const uploadPurchaseOrder = (file: File) => {
    const formData = new FormData();
    formData.append("purchase_order", file);
    return createPostRequest(
        "/manageQr/uploadPurchaseOrder",
        formData,
        "multipart/form-data"
    );
};

//6. ---------------------------Generate QR Codes PO Level---------------------------
export const generateQrPoLevel = (formData: FormData) => {
    return createPostRequest(
        "/manageQr/generateQrPoLevel",
        formData,
        "multipart/form-data"
    );
};

//7. ---------------------------Fetch Purchase Order Detials----------------------------
export const fetchPurchaseOrderDetails = (purchaseOrderNumber: string) => {
    return createPostRequest("/manageQr/fetchPurchaseOrderDetails", {
        purchaseOrderNumber,
    });
};

//8. ---------------------------Generate Qr Codes----------------------------
// Update the generateQrCodes function to accept FormData
export const generateQrCodes = (formData: FormData) => {
    return createPostRequest(
        "/manageQr/generateQr",
        formData,
        "multipart/form-data"
    );
};

//9. ---------------------------Raise Request For PO Item----------------------------
export const raiseRequestForPoItem = (data: {
    purchase_order_number: string;
    item_code_number: string;
}): Promise<AxiosResponse> => {
    return createPostRequest("/manageQr/raiseRequestForPoItem", data);
};

//10. ---------------------------Approve Request For Po Item----------------------------
export const approveRequestForPoItem = (data: {
    purchase_order_number: string;
    item_code_number: string;
}): Promise<AxiosResponse> => {
    return createPostRequest("/manageQr/approveRequestForPoItem", data);
};

//11. ---------------------------Get QR History----------------------------
export const getQrHistory = (): Promise<AxiosResponse> => {
    return createGetRequest("/manageQr/qrHistory");
};

//12. ---------------------------Qr Code Overview----------------------------
export const getQrCodeOverview = () => {
    return createGetRequest("/manageQr/getQrCodeOverview");
};

export const getTotalNumberOfGRNagainstPO = (data:{
    purchaseOrderId?:number,
    itemCodeId?:number
}) =>{
    return createPostRequest("/manageQr/TotalNumberofGrnagainstPO",data)
}

export const fetchPurchaseOrderList = () =>{
    return createGetRequest("/manageQr/fetchPurchaseOrderList",{})
}

export const fetchGrnDataAgainstEachMonth = (data:{
    year:number
}) =>{
    return createPostRequest("/manageQr/getGrnCountByMonth",data)
}

export const fetchItemCodeListAgainstPo = (purchaseOrderId:number) =>{
    return createPostRequest("/manageQr/fetchItemCodeListAgainstPo",{
        purchaseOrderId
    })
}

//13. ---------------------------Sku Wise Qr Codes Generated----------------------------
export const getSkuWiseQrCodesGenerated = (itemCode: string, year: string) => {
    return createPostRequest("/manageQr/getSkuWiseQrCodesGenerated", {
        itemCode,
        year,
    });
};

//14. ---------------------------Item Codes----------------------------
export const getItemCodes = () => {
    return createGetRequest("/manageQr/getItemCodes");
};

//15. ---------------------------Total Number Of Qrs Generated Year Wise------------------------
export const getTotalNumberOfQrsGeneratedYearWise = (year: number) => {
    return createPostRequest("/manageQr/getTotalNumberOfQrsGeneratedYearWise", {
        year,
    });
};

//16. ---------------------------Count Of Purchase Orders----------------------------
export const getCountOfPurchaseOrders = () => {
    return createGetRequest("/manageQr/getCountOfPurchaseOrders");
};

//17. ---------------------------Count Of Purchase Orders Year Wise----------------------------
export const getCountOfPurchaseOrdersYearWise = (year: number) => {
    return createPostRequest("/manageQr/getCountOfPurchaseOrdersYearWise", {
        year,
    });
};

//18. ---------------------------Total Number Of Qrs Generated Year Wise For A Vendor-------------------------
export const getTotalNumberOfQrsGeneratedYearWiseForAVendor = (data: {
    user_id: number;
    year: number;
}): Promise<AxiosResponse> => {
    return createPostRequest(
        "/manageQr/getTotalNumberOfQrsGeneratedYearWiseForAVendor",
        data
    );
};

//19. ---------------------------Sku Wise Active Inactive Qr Status----------------------------
export const getSkuWiseActiveInactiveQrStatus = (data: {
    itemCode: string;
    year: number;
}): Promise<AxiosResponse> => {
    return createPostRequest("/manageQr/activeInActiveQrStatusSkuWise", data);
};

//20. ---------------------------Qr Count By Statuses----------------------------
export const getQrCountByStatuses = (year: number): Promise<AxiosResponse> => {
    return createPostRequest("/manageQr/getQrCountByStatuses", { year });
};

//21. ---------------------------Get User List----------------------------
export const getUserList = (): Promise<AxiosResponse> => {
    return createGetRequest("/manageQr/getUserList");
};

//22. ---------------------------User Sign Up----------------------------
export const userSignUp = (data: {
    userMobile: string;
    userType: string;
    userCode?: string;
    userFullName: string;
    userEmail: string;
    printerId?: string;
    bindingip: string;
}) => {
    return createPostRequest("/auth/userSignUp", data);
};

//23. ---------------------------Fetch Users----------------------------
export const fetchUsers = () => {
    return createPostRequest("/auth/fetchUsers", {});
};


//24. ---------------------------Fetch Printers----------------------------
export const fetchPrinters = (): Promise<AxiosResponse> => {
    return createGetRequest("/auth/fetchPrinters");
};

//25. ---------------------------Fetch Purchase Order Numbers----------------------------
export const fetchPurchaseOrderNumbers = () => {
    return createGetRequest("/manageQr/fetchPurchaseOrderNumbers");
};

//26. ---------------------------Vendor Wise PO Report----------------------------
export const getVendorWisePOSummary = (data: {
    year?: number;
    month?: number;
    sku?: string;
    purchase_order_number?: string;
    exportExcel: boolean;
    vendor_id?: number;
}) => {
    if (data.exportExcel) {
        return createPostRequest("/manageQr/vendorWisePoSummary", data, undefined, {
            responseType: "arraybuffer",
        });
    }
    return createPostRequest("/manageQr/vendorWisePoSummary", data);
};

//27. ---------------------------Qr Month Wise Report----------------------------
export const getQrMonthWiseReport = (data: {
    year?: number;
    exportExcel: boolean;
    vendor_id?: number;
}): Promise<AxiosResponse> => {
    if (data.exportExcel) {
        return createPostRequest(
            "/manageQr/getQrMonthWiseReport",
            data,
            undefined,
            { responseType: "arraybuffer" }
        );
    }
    return createPostRequest("/manageQr/getQrMonthWiseReport", data);
};

// Function to fetch SKU-wise QR report
export const getQrSkuWiseReport = async (data: {
    year: number;
    exportExcel: boolean;
    vendor_id: number;
}): Promise<AxiosResponse> => {
    if (data.exportExcel) {
        return createPostRequest("/manageQr/getQrSkuWiseReport", data, undefined, {
            responseType: "arraybuffer",
        });
    }
    return createPostRequest("/manageQr/getQrSkuWiseReport", data);
};

export const getQrSkuWiseAndMonthWiseReport = (data: {
    year: number;
    sku?: string;
    exportExcel: boolean;
    vendor_id?: number;
}): Promise<AxiosResponse> => {
    if (data.exportExcel) {
        return createPostRequest(
            "/manageQr/getQrSkuWiseAndMonthWiseReport",
            data,
            undefined,
            { responseType: "arraybuffer" }
        );
    }
    return createPostRequest("/manageQr/getQrSkuWiseAndMonthWiseReport", data);
};

export const getQrVendorWiseMonthWiseReport = (data: {
    year: number;
    exportExcel: boolean;
    vendor_id: number;
}): Promise<AxiosResponse> => {
    if (data.exportExcel) {
        return createPostRequest(
            "/manageQr/getQrVendorWiseMonthWiseReport",
            data,
            undefined,
            { responseType: "arraybuffer" }
        );
    }
    return createPostRequest("/manageQr/getQrVendorWiseMonthWiseReport", data);
};

// First, let's add the new API function to your services file:
export const getVendorWisePurchaseOrderSummary = (): Promise<AxiosResponse> => {
    return createGetRequest("/manageQr/vendorWisePurchaseOrderSummary");
};

export const downloadQrForAdmin = (purchase_order_number: string) => {
    return createPostRequest("/manageQr/downloadQrForAdminn", {
        purchase_order_number,
    });
};

// Add this function to your existing API services file
export const getQrGenerationLogs = (): Promise<AxiosResponse> => {
    return createPostRequest("/manageQr/getQrGenerationLogs", {}); // Empty object as body
};

// Add this function to your existing API services file
export const getPurchaseOrderUploadLogs = (): Promise<AxiosResponse> => {
    return createPostRequest("/manageQr/getPurchaseOrderUploadLogs", {}); // Empty object as body
};

// First, add this to your API services file:
export const getAccessRequestLogs = (): Promise<AxiosResponse> => {
    return createPostRequest("/manageQr/getAccessRequestLogs", {});
};


export const fetchCommunicationLogs = (): Promise<AxiosResponse> => {
    return createPostRequest("/manageQr/fetchCommunicationLogs", {});
};

export const fetchExpiryPolicy = () => {
    return createGetRequest("/manageQr/FetchExpiryPolicy");
};

export const updateExpiryPolicy = (data: any) => {
    return createPostRequest("/manageQr/UpdateExpiryPolicy", data);
};

export const updateUserStatus = (data: {
    user_id: number;
    user_status?: 'active' | 'inactive';
}): Promise<AxiosResponse> => {
    return createPostRequest("/auth/updateUser", data);
};

export const fetchCameraPhoto = (data: {
    purchase_order_number: string;
    item_code_number: string;
}): Promise<AxiosResponse> => {
    return createPostRequest("/manageQr/fetchCameraPhoto", data);
}

export const downloadQrBatchWise = (data: {
    purchase_order_number: string;
    item_code: string;
}): Promise<AxiosResponse> => {
    return createPostRequest("/manageQr/downloadQrBatchWise", data);
};

export const rejectRequestForPoItem = (data: {
    purchase_order_number: string;
    item_code_number: string;
}): Promise<AxiosResponse> => {
    return createPostRequest("/manageQr/rejectRequestForPoItem", data);
};

export const getMonthWisePOData = (data: {
    exportExcel: boolean;
    year: number;
    user_id?: number;
}): Promise<AxiosResponse> => {
    if (data.exportExcel) {
        return createPostRequest("/manageQr/monthWisePoData", data, undefined, {
            responseType: "arraybuffer",
        });
    }
    return createPostRequest("/manageQr/monthWisePoData", data);
};

// Add this to your existing API services file
export const fetchUserSessionLogs = (): Promise<AxiosResponse> => {
    return createGetRequest("/manageQr/userSessionLogs");
};

// Update in your API services file
export const userLogout = (data: {
    latitude: string;
    longitude: string;
}): Promise<AxiosResponse> => {
    return createPostRequest("/auth/userLogout", data);
};

// export const userLogout = () => {
//     const latitude = sessionStorage.getItem("latitude");
//     const longitude = sessionStorage.getItem("longitude");

//     // Add geolocation to the body if available
//     const requestBody = {
//         ...(latitude && longitude && { latitude, longitude }),
//     };

//     return createPostRequest("/auth/userLogout", requestBody);
// };