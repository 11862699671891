import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import './GRN-Details.css';
import { ReactComponent as YourIcon } from '../../../assets/icons/grn.svg';
import { fetchGrnDataAgainstEachMonth } from '../../../utils/services/apiService';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const GRNDetailsBarChart: React.FC = () => {
  const [selectedYear, setSelectedYear] = useState<number>(2024);
  const [chartData, setChartData] = useState<number[]>(Array(12).fill(0));
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    fetchGrnData(selectedYear)
  }, [selectedYear])
  const fetchGrnData = async (year:number) => {
    try {
      const response = await fetchGrnDataAgainstEachMonth({ year: year });
      if (response && response.status === 200 && response.data) {
        const monthlyData = Array(12).fill(0);
        response.data.data.data.forEach((item: { month: number; grnsum: string }) => {
          monthlyData[(item.month - 1 + 9) % 12] = Number(item.grnsum);
        });
        setChartData(monthlyData);
      }
    } catch (err) {
      console.log(err, "afvqacq")
      setError('Failed to load data')
    }
  }
  // const handleYearChange = (event: SelectChangeEvent<number>) => {
  //     setSelectedYear(Number(event.target.value));
  //   };
  // fetchGrnData();
  const data = {
    labels: ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec','Jan', 'Feb', 'Mar'],
    datasets: [
      {
        label: 'GRN Details',
        data: chartData,
        backgroundColor: '#00AAFF',
        barThickness: 28,
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 6,
          callback: (value: string | number) => {
            const numValue = typeof value === 'number' ? value : parseFloat(value as string);
            return numValue === 0 ? '0' : `${numValue / 1000}k`;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: '#f0f0f0',
        titleColor: 'black',
        bodyColor: 'black',
        borderColor: 'lightgrey',
        borderWidth: 1,
        padding: 10,
        displayColors: false,
        callbacks: {
          title: (context) => '',
          label: (context) => {
            const month = context.label;
            const value = context.parsed.y.toLocaleString();
            return [`${value} Purchase Orders`, `${month} ${!['Jan', 'Feb', 'Mar'].includes(month) ? selectedYear : selectedYear + 1 }`];
          },
        },
      },
    },
  };

  const totalOrders = data.datasets[0].data.reduce((sum, value) => sum + value, 0);

  return (
    <div className="grn-details-chart">
      <div className="chart-header">
        <YourIcon className="chart-icon" />
        <div className="header-text">
          <h3>Total GRN Details Received</h3>
          <p>For the year of {selectedYear}</p>
        </div>
        <FormControl sx={{ width: '20%' }}>
          <InputLabel>Year</InputLabel>
          <Select
            value={selectedYear}
            onChange={(e) => setSelectedYear(Number(e.target.value))}
            label="Year"
          >
            <MenuItem value={2022}>2022-2023 </MenuItem>
            <MenuItem value={2023}>2023-2024 </MenuItem>
            <MenuItem value={2024}>2024-2025 </MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="chart-summary">
        <h1>{totalOrders.toLocaleString()}</h1>
        <p>GRN details have been received</p>
      </div>
      <div className="chart-container">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default GRNDetailsBarChart;