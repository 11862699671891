import React, {
    createContext,
    useContext,
    useState,
    ReactNode,
    useEffect,
} from "react";
import { userLogout } from "./services/apiService";

interface AuthContextType {
    isLoggedIn: boolean;
    login: (token: string) => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
        !!sessionStorage.getItem("accessToken")
    );

    const performLogout = async () => {
        try {
            const latitude = sessionStorage.getItem("latitude");
            const longitude = sessionStorage.getItem("longitude");

            if (latitude && longitude) {
                await userLogout({
                    latitude,
                    longitude
                });
            } else {
                console.warn("Geo Location Not Found");
            }
        } catch (error) {
            console.error("Logout Failed", error);
        } finally {
            localStorage.removeItem("isAuthenticated");
            localStorage.removeItem("activeKey");
            sessionStorage.removeItem("userType");
            sessionStorage.removeItem("refreshToken");
            sessionStorage.removeItem("userData");
            sessionStorage.removeItem("accessToken");
            setIsLoggedIn(false);
        }
    };

    useEffect(() => {
        const token = sessionStorage.getItem("accessToken");
        setIsLoggedIn(!!token);

        // Prevent automatic logout on page refresh
        const handlePageShow = (event: PageTransitionEvent) => {
            if (event.persisted) {
                setIsLoggedIn(!!sessionStorage.getItem("accessToken"));
            }
        };
        window.addEventListener('pageshow', handlePageShow);

        return () => {
            window.removeEventListener('pageshow', handlePageShow);
        };
    }, []);

    const login = (token: string) => {
        sessionStorage.setItem("accessToken", token);
        setIsLoggedIn(true);
    };

    const logout = () => {
        performLogout();
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};