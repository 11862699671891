import React, { useState, useEffect } from 'react';
import {
  LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer,
} from 'recharts';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';
import { ReactComponent as LocalIcon } from '../../../assets/icons/skus.svg';
import { getSkuWiseActiveInactiveQrStatus, getItemCodes } from '../../../utils/services/apiService';
import './SKU-Status-Line-Graph.css';

interface SKUData {
  month: string;
  Active: number;
  Inactive: number;
}

interface MonthData {
  month: string;
  count: number;
}

// interface ItemCode {
//   value: string;
//   label: string;
// }
interface ItemCode {
  item_code: string;
  description: string;
  concat_string: string;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: { name: string; value: number }[];
  label?: string;
}

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const activeSkus = payload.find((item) => item.name === 'Active')?.value || 0;
    const inactiveSkus = payload.find((item) => item.name === 'Inactive')?.value || 0;
    return (
      <div className="custom-tooltip">
        <p className="tooltip-active-skus">
          {`${activeSkus} Active SKUs`}
        </p>
        <p className="tooltip-inactive-skus">
          {`${inactiveSkus} Inactive SKUs`}
        </p>
        <p className="tooltip-month-year">
          {`${label}, ${new Date().getFullYear()}`}
        </p>
      </div>
    );
  }
  return null;
};

const SKUsStatusChart: React.FC = () => {
  const [selectedYear, setSelectedYear] = useState<string>('2024');
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [selectedSKU, setSelectedSKU] = useState<string>('');
  const [skuData, setSkuData] = useState<SKUData[]>([]);
  const [itemCodes, setItemCodes] = useState<ItemCode[]>([]);

  const calculateYAxisTicks = (data: SKUData[]) => {
    const maxValue = Math.max(...data.map(d => Math.max(d.Active, d.Inactive)));
    const interval = Math.ceil(maxValue / 5);
    const ticks = Array.from({ length: 6 }, (_, i) => i * interval);
    return ticks;
  };

  useEffect(() => {
    const fetchSkuData = async () => {
      try {
        const response = await getSkuWiseActiveInactiveQrStatus({
          itemCode: selectedSKU,
          year: parseInt(selectedYear),
        });

        if (response?.data?.data?.activeInactiveQrSkuWise) {
          const { activeRequests, inactiveRequests } = response.data.data.activeInactiveQrSkuWise;
          
          const transformedData: SKUData[] = activeRequests.map((active: MonthData, index: number) => ({
            month: monthNames[parseInt(active.month) - 1],
            Active: active.count,
            Inactive: inactiveRequests[index].count,
          }));
          
          setSkuData(transformedData);
        }
      } catch (error) {
        console.error('Error fetching SKU data:', error);
      }
    };

    if (selectedSKU || selectedYear) {
      fetchSkuData();
    }
  }, [selectedYear, selectedSKU]);

  useEffect(() => {
    const fetchItemCodes = async () => {
      try {
        const response = await getItemCodes();
        // const itemCodeOptions = response.data.data.map((code: string) => ({
        //   value: code,
        //   label: code,
        // }));
        if (response && response.status === 200 && response.data?.data) {
          const codes = response.data.data || [];
          setItemCodes(codes);
          if (codes.length > 0) {
            setSelectedSKU(codes[0].item_code);
          }
      }
        // setItemCodes(itemCodeOptions);
        // if (itemCodeOptions.length > 0) {
        //   setSelectedSKU(itemCodeOptions[0].value);
        // }
      } catch (error) {
        console.error('Error fetching item codes:', error);
      }
    };

    fetchItemCodes();
  }, []);

  const handleYearChange = (event: SelectChangeEvent<string>) => {
    setSelectedYear(event.target.value as string);
  };

  const handleFilterClick = () => {
    setIsFilterOpen(true);
  };

  const handleFilterClose = () => {
    setIsFilterOpen(false);
  };

  const handleSKUChange = (event: SelectChangeEvent<string>) => {
    setSelectedSKU(event.target.value as string);
  };

  return (
    <div className="skus-status-chart">
      <div className="chart-header">
        <div className="title-section">
          <div className="icon">
            <LocalIcon width={40} height={40} />
          </div>
          <div className="title-text">
            <h2>SKUs with Active and Inactive Status</h2>
            <p className="subtitle">For the year of {selectedYear}</p>
          </div>
        </div>
        <div className="controls">
        <FormControl fullWidth sx={{ width: '116px', marginTop: '4px', marginRight: '10px' }}>
  <InputLabel>Year</InputLabel>
  <Select
    value={selectedYear}
    onChange={handleYearChange}
    label="Year"
  ><MenuItem value="2022">2022-2023</MenuItem>
   <MenuItem value="2023">2023-2024</MenuItem>
    <MenuItem value="2024">2024-2025</MenuItem>
    
  </Select>
</FormControl>

          <button
            title="filter"
            className="filter-btn"
            onClick={handleFilterClick}
          >
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
              <path d="M22 3H2l8 9.46V19l4 2v-8.54L22 3z" stroke="#000" strokeWidth="2"/>
            </svg>
          </button>
        </div>
      </div>

      <div className="legend">
        <div className="legend-item">
          <span className="dot active"></span>
          <span>Active</span>
        </div>
        <div className="legend-item">
          <span className="dot inactive"></span>
          <span>Inactive</span>
        </div>
      </div>

      <div className="chart-container">
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={skuData}>
            <XAxis dataKey="month" />
            <YAxis ticks={calculateYAxisTicks(skuData)} />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type="monotone"
              dataKey="Active"
              stroke="#FFB800"
              strokeWidth={2}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="Inactive"
              stroke="#0066FF"
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <Dialog open={isFilterOpen} onClose={handleFilterClose} className="filter-dialog">
  <DialogTitle sx={{ marginBottom: '8px' }}>Select SKU to Filter</DialogTitle>
  <DialogContent className="dialog-content" sx={{ paddingTop: '16px', paddingBottom: '16px' }}>
    <FormControl fullWidth sx={{ width: '200px', marginTop: '8px' }}>
      <InputLabel sx={{ fontSize: '1rem', lineHeight: '1.5' }}>SKU</InputLabel>
      <Select
        value={selectedSKU}
        onChange={handleSKUChange}
        label="SKU"
      >
        {itemCodes.map((item) => (
          <MenuItem key={item.concat_string} value={item.item_code}>
            {item.concat_string}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </DialogContent>
  <DialogActions className="dialog-actions">
    <Button onClick={handleFilterClose} color="primary" variant="contained">
      Apply
    </Button>
    <Button onClick={handleFilterClose} color="secondary" variant="outlined">
      Cancel
    </Button>
  </DialogActions>
</Dialog>

    </div>
  );
};

export default SKUsStatusChart;
