import React, { useState, useEffect } from 'react';
import { Gauge } from '@mui/x-charts/Gauge';
import { styled } from '@mui/material/styles';
import './GRN-GaugChart.css';
import { getTotalNumberOfGRNagainstPO, fetchItemCodeListAgainstPo, fetchPurchaseOrderList } from '../../../utils/services/apiService';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const StyledGauge = styled(Gauge)(({ theme }) => ({
  width: '100%',
  height: 375,
  '& text': {
    display: 'none',
  },
  '& .MuiCharts-gaugeArc': {
    strokeWidth: 12,
  },
}));

interface PurchaseOrder {
  id: number;
  purchase_order_number: string;
}

interface ItemCode {
  id: number;
  item_code: string;
  description: string;
  concatenatedString: string;
}


const GRNGaugeChart: React.FC = () => {
  const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder[]>([]);
  const [selectedPurchaseOrderId, setSelectedPurchaseOrderId] = useState<number>(0);
  const [selectedItemCodeId, setSelectedItemCodeId] = useState<number>(0);
  const [itemCodes, setItemCodes] = useState<ItemCode[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [totalNumberOfGrnAgainstPo, setTotalNumberOfGrnAgainstPo] = useState<string>()
  useEffect(() => {
    setTotalNumberofGRNAgainstPOCardValue(selectedPurchaseOrderId,selectedItemCodeId)
    fetchPurchaseOrders();
    fetchItemCodes(selectedPurchaseOrderId);
  }, [selectedItemCodeId,selectedPurchaseOrderId])
  const setTotalNumberofGRNAgainstPOCardValue = async (purchaseOrderId?:number,itemCodeId?:number) => {
    try {
      const response = await getTotalNumberOfGRNagainstPO({
        purchaseOrderId:purchaseOrderId,itemCodeId:itemCodeId
      });

      if (response && response.status === 200 && response.data) {
        console.log(response.data)
        setTotalNumberOfGrnAgainstPo(response.data?.data || "0");
      }
    } catch (err) {
      setError('Failed to load data')
    }
  }
  const fetchPurchaseOrders = async () => {
    try {
      const response = await fetchPurchaseOrderList();
      if (response && response.status === 200 && response.data) {
        setPurchaseOrder(response?.data?.data || []);
      }
    } catch (err) {
      setError('Failed to load data')
    }
  }

  const fetchItemCodes = async (purchaseOrderId: number) => {
    try {
      const response = await fetchItemCodeListAgainstPo(purchaseOrderId);
      if (response && response.status === 200 && response.data) {
        setItemCodes(response?.data?.data || []);
      }
    } catch (err) {
      setError('Failed to load data')
    }
  }
  const handlePurchaseOrderChange = (purchaseOrderId: number) => {
    try {
      setSelectedPurchaseOrderId(purchaseOrderId);
      // fetchItemCodes(purchaseOrderId);
    } catch (err) {
      setError('Failed to setSelectedPurchaseOrderId')
    }
  }

  const handleItemCodeChange = (itemCodeId: number) => {
    try {
      setSelectedItemCodeId(itemCodeId);
    } catch (err) {
      setError('Failed to setSelectedItemCodeId')
    }
  }
  return (

    <div className="grn-gauge-container" >
      <div className="gauge-header">
        <div className="icon-wrapper">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
              stroke="#E91E63"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7 7H17"
              stroke="#E91E63"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M7 12H17"
              stroke="#E91E63"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M7 17H17"
              stroke="#E91E63"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <h2>Total Number of GRN against PO</h2>
      </div>
      <Autocomplete
        disablePortal
        options={purchaseOrder}
        getOptionLabel={(option) => option.purchase_order_number}
        sx={{ width: 250,marginBottom: 2, }}
        onChange={(event, newValue) => handlePurchaseOrderChange(newValue?.id as number)}
        renderInput={(params) => <TextField {...params} label="Purchase Order" />}
      />
      <Autocomplete
        disablePortal
        options={itemCodes}
        getOptionLabel={(option) => option.concatenatedString}
        sx={{ width: 250 }}
        onChange={(event, newValue) => handleItemCodeChange(newValue?.id as number)}
        renderInput={(params) => <TextField {...params} label="Sku Code" />}
      />
      {/* <div className="percentage-increase">
        <span>+35% since yesterday</span>
      </div> */}
      <div className="gauge-chart" >
        <StyledGauge
          value={82.8} // Keeps the value for the gauge arc percentage
          startAngle={-110}
          endAngle={110}
        />
        <div className="gauge-center-content">
          <div className="gauge-value">{totalNumberOfGrnAgainstPo}</div>
          <div className="gauge-subtitle">GRN against Purchase Order</div>
        </div>
      </div >
    </div >
  );
};

export default GRNGaugeChart;
