import React, { useEffect, useState } from 'react';
import MyCustomIcon from '../../../assets/icons/vendor.svg';
import PurchaseOrderIcon from '../../../assets/icons/v1.svg';
import GrnIcon from '../../../assets/icons/v2.svg';
import QualityCheckIcon from '../../../assets/icons/v3.svg';
import ActivatedIcon from '../../../assets/icons/v4.svg';
import { getVendorWisePurchaseOrderSummary } from '../../../utils/services/apiService';
import './Vendorwise-Purchase-Order.css';

interface VendorSummaryData {
    vendorId: number;
    vendorName: string;
    totalGrn: number;
    totalQc: number;
    totalActive: number;
    purchase_order_count: number;
}

interface SummaryCardProps {
    icon: React.ReactNode;
    value: number;
    label: string;
}

const SummaryCard: React.FC<SummaryCardProps> = ({ icon, value, label }) => (
    <div className="vendor-card">
        <div className="vendor-card-header">
            {icon}
        </div>
        <div className="vendor-card-value">
            {value}
        </div>
        <div className="vendor-card-label">{label}</div>
    </div>
);

const VendorwisePurchaseOrderSummary: React.FC = () => {
    const [vendorData, setVendorData] = useState<VendorSummaryData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchVendorData = async () => {
            try {
                const response = await getVendorWisePurchaseOrderSummary();
                if (response.data.status === 200) {
                    setVendorData(response.data.data);
                } else {
                    setError('Failed to fetch vendor data');
                }
            } catch (err) {
                setError('An error occurred while fetching vendor data');
            } finally {
                setLoading(false);
            }
        };

        fetchVendorData();
    }, []);

    if (loading) {
        return <div className="flex justify-center items-center h-64">Loading...</div>;
    }

    if (error) {
        return <div className="text-red-500 text-center">{error}</div>;
    }

    return (
        <div className="vendor-summary-container">
            <div className="vendor-summary-header">
                <div className="vendor-summary-icon">
                    <img src={MyCustomIcon} alt="Custom Icon" className="vendor-icon" />
                </div>
                <h2 className="vendor-summary-title">Vendorwise Purchase Order Summary</h2>
            </div>

            <div className="vendor-summary-cards">
                {vendorData.map((vendor) => (
                    <div key={vendor.vendorId} className="mb-6">
                        <h3 className="text-lg font-medium mb-3">
                            Vendor name: {vendor.vendorName}
                        </h3>
                        <div className="vendor-summary-grid">
                            <SummaryCard
                                icon={<img src={PurchaseOrderIcon} alt="Purchase Order Icon" className="card-icon" />}
                                value={vendor.purchase_order_count}
                                label="Total Purchase Orders"
                            />
                            <SummaryCard
                                icon={<img src={GrnIcon} alt="GRN Icon" className="card-icon" />}
                                value={vendor.totalGrn}
                                label="Total Number of GRN"
                            />
                            <SummaryCard
                                icon={<img src={QualityCheckIcon} alt="Quality Check Icon" className="card-icon" />}
                                value={vendor.totalQc}
                                label="GRN Pending"
                            />
                            <SummaryCard
                                icon={<img src={ActivatedIcon} alt="Activated Icon" className="card-icon" />}
                                value={vendor.totalActive}
                                label="Total Activated"
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default VendorwisePurchaseOrderSummary;