import React, { useState, useEffect } from 'react';
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import { Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import { getQrCountByStatuses } from '../../../utils/services/apiService';
import './QR-Code-Graph.css';

interface QrCountData {
  activeCounts: Array<{ month: string; count: number }>;
  inactiveCounts: Array<{ month: string; count: number }>;
  generatedCounts: Array<{ month: string; count: number }>;
  downloadedCounts: Array<{ month: string; count: number }>;
  grnCounts: Array<{ month: string; count: number }>;
  qcCounts: Array<{ month: string; count: number }>;
  rejectedCounts: Array<{ month: string; count: number }>;
  inprogressCounts: Array<{ month: string; count: number }>;
}

const monthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const QRCodeGraph: React.FC = () => {
  const [selectedYear, setSelectedYear] = useState<number>(2024);
  const [filterOption, setFilterOption] = useState<keyof QrCountData>('activeCounts');
  const [qrCountData, setQrCountData] = useState<QrCountData>({
    activeCounts: [],
    inactiveCounts: [],
    generatedCounts: [],
    downloadedCounts: [],
    grnCounts: [],
    qcCounts: [],
    rejectedCounts: [],
    inprogressCounts: [],
  });

  useEffect(() => {
    const fetchQrCountData = async () => {
      try {
        const response = await getQrCountByStatuses(selectedYear);
        setQrCountData(response.data.data.CountOfPurchaseOrders);
      } catch (error) {
        console.error('Error fetching QR count data:', error);
      }
    };
    fetchQrCountData();
  }, [selectedYear]);

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setFilterOption(event.target.value as keyof QrCountData);
  };

  const handleYearChange = (event: SelectChangeEvent<number>) => {
    setSelectedYear(Number(event.target.value));
  };

  const filterValue = qrCountData[filterOption].reduce((total, item) => total + item.count, 0);

  // Calculate the maximum count for the selected filter option
  const maxCount = Math.max(...qrCountData[filterOption].map(item => item.count), 0);
  const yAxisDomain = [0, Math.ceil(maxCount / 100) * 100]; // Rounds up to the nearest hundred

  return (
    <div className="qr-code-graph">
      <div className="graph-header">
        <div className="title-section">
          <div className="icon-container">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M3 3H10V10H3V3Z" stroke="#00C4F7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M14 3H21V10H14V3Z" stroke="#00C4F7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M3 14H10V21H3V14Z" stroke="#00C4F7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M14 14H21V21H14V14Z" stroke="#00C4F7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <div className="title-text">
            <h2>Total Number of QR Codes</h2>
            <p>For the year of {selectedYear}</p>
          </div>
        </div>

        <div className="dropdown-section">
          <FormControl sx={{ width: '190px' }}>
            <InputLabel>Status</InputLabel>
            <Select value={filterOption} onChange={handleFilterChange} label="Status">
              <MenuItem value="activeCounts">Active Counts</MenuItem>
              <MenuItem value="inactiveCounts">Inactive Counts</MenuItem>
              <MenuItem value="generatedCounts">Generated Counts</MenuItem>
              <MenuItem value="downloadedCounts">Downloaded Counts</MenuItem>
              <MenuItem value="grnCounts">GRN Counts</MenuItem>
              <MenuItem value="qcCounts">QC Counts</MenuItem>
              <MenuItem value="rejectedCounts">Rejected Counts</MenuItem>
              <MenuItem value="inprogressCounts">In-Progress Counts</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ width: '120px', marginLeft: '10px' }}>
            <InputLabel>Year</InputLabel>
            <Select
              value={selectedYear}
              onChange={handleYearChange}
              label="Year"
            >
              <MenuItem value={2022}>2022-2023</MenuItem>
              <MenuItem value={2023}>2023-2024</MenuItem>
              <MenuItem value={2024}>2024-2025</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="metrics">
        <h1>{filterValue.toLocaleString()}</h1>
        <p>{`${filterOption.replace(/([A-Z])/g, ' $1').toUpperCase()}`}</p>
      </div>

      <div className="graph-container">
        <ResponsiveContainer width="70%" height={300}>
          <AreaChart data={qrCountData[filterOption]}>
            <defs>
              <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#E5F7FF" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#E5F7FF" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="month"
              axisLine={false}
              tickLine={false}
              tick={{ fill: '#64748B' }}
              tickFormatter={(month) => monthLabels[parseInt(month, 10) - 1]}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{ fill: '#64748B' }}
              domain={yAxisDomain}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: '#333',
                border: 'none',
                borderRadius: '4px',
                color: 'white',
                padding: '8px',
              }}
            />
            <Area
              type="monotone"
              dataKey="count"
              stroke="#00C4F7"
              fillOpacity={1}
              fill="url(#colorValue)"
              strokeWidth={2}
              dot={{ r: 4, fill: '#00C4F7' }}
              activeDot={{ r: 6, fill: '#00C4F7' }}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default QRCodeGraph;