// CreateUser.tsx

import React, { useState, useEffect } from 'react';
import { Input } from 'rsuite';
import { Typography, Breadcrumbs, Link, Select, MenuItem, Snackbar, Alert } from '@mui/material';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import 'rsuite/dist/rsuite.min.css';
import './CreateUser.css';
import Header from '../dashboard/components/Header';
import { userSignUp, fetchPrinters, updateUserStatus } from '../../utils/services/apiService';

// Interfaces
interface FormData {
    fullName: string;
    userRole: string;
    email: string;
    mobileNumber: string;
    ipBinding: string;
    vendorCode?: string;
    //printerId?: string;
    pan?: string;
    gstin?: string;
    address?: string;
    printerShortCode?:string;
}

interface Printer {
    id: number;
    printer_name: string;
    printer_short_code: string;
}

interface ValidationErrors {
    email?: string;
    mobileNumber?: string;
    vendorCode?: string;
    pan?: string;
    gstin?: string;
}

interface LocationState {
    editMode: boolean;
    userData: {
        userFullName: string;
        userEmail: string;
        userCode: string;
        bindingIp: string;
        userType: string;
        userMobile: string;
        printerShortCode?: string;
    };
}

const CreateUser: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const editUserId = searchParams.get('edit');
    const { editMode, userData } = (location.state as LocationState) || { editMode: false, userData: null };

    const [isEditMode, setIsEditMode] = useState(false);
    const [formData, setFormData] = useState<FormData>({
        fullName: '',
        userRole: '',
        email: '',
        mobileNumber: '',
        ipBinding: '',
        vendorCode: '',
        printerShortCode:"",
        pan: '',
        gstin: '',
        address: ''
    });

    const [printers, setPrinters] = useState<Printer[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const roleData = [
        { label: 'Admin', value: 'admin' },
        { label: 'Analytics', value: 'analytics' },
        { label: 'Vendor', value: 'vendor' },
    ];

    // Initialize form data in edit mode
    useEffect(() => {
        if (editMode && userData) {
            setIsEditMode(true);
            setFormData({
                fullName: userData.userFullName || '',
                userRole: userData.userType || '',
                email: userData.userEmail || '',
                mobileNumber: userData.userMobile || '',
                ipBinding: userData.bindingIp || '',
                vendorCode: userData.userCode || '',
                printerShortCode: userData.printerShortCode || '',
                pan: '',
                gstin: '',
                address: ''
            });
            if (userData.userType === 'vendor') {
                loadPrinters();
            }
        }
    }, [editMode, userData]);

    // Load printers when role is vendor
    useEffect(() => {
        if (formData.userRole === 'vendor') {
            loadPrinters();
        }
    }, [formData.userRole]);

    const loadPrinters = async () => {
        try {
            const response = await fetchPrinters();
            if (response.status === 200) {
                setPrinters(response.data.data);
            } else {
                setError('Failed to load printers. Please try again.');
            }
        } catch (err) {
            console.error('Error loading printers:', err);
            setError('Failed to load printers. Please try again.');
        }
    };

    // Field visibility control
    const isFieldVisible = (fieldName: string): boolean => {
        if (!isEditMode) return true;
        
        const editableFields = ['fullName', 'email', 'ipBinding', 'vendorCode', 'printerId'];
        return editableFields.includes(fieldName);
    };

    // Form validation
    const validateForm = (): boolean => {
        const errors: ValidationErrors = {};

        if (!isEditMode) {
            // Validation for create mode
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(formData.email)) {
                errors.email = 'Please enter a valid email address';
            }

            const mobileRegex = /^\d{10}$/;
            if (!mobileRegex.test(formData.mobileNumber)) {
                errors.mobileNumber = 'Please enter a valid 10-digit mobile number';
            }

            if (formData.userRole === 'vendor') {
                if (!formData.vendorCode || formData.vendorCode.trim() === '') {
                    errors.vendorCode = 'Vendor code is required';
                }
                if (!formData.pan || !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(formData.pan)) {
                    errors.pan = 'Please enter a valid PAN number';
                }
                if (!formData.gstin || !/^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}[A-Z\d]{1}$/.test(formData.gstin)) {
                    errors.gstin = 'Please enter a valid GSTIN';
                }
            }
        } else {
            // Validation for edit mode
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(formData.email)) {
                errors.email = 'Please enter a valid email address';
            }
        }

        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleInputChange = (field: keyof FormData, value: string): void => {
        setError(null);
        setValidationErrors(prev => ({ ...prev, [field]: undefined }));
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleSubmit = async (): Promise<void> => {
        if (!validateForm()) {
            return;
        }
    
        try {
            setIsSubmitting(true);
            setError(null);
            setValidationErrors({});
    
            if (isEditMode) {
                // Base update data without printer_id
                const baseUpdateData = {
                    user_id: parseInt(editUserId || ''),
                    user_full_name: formData.fullName,
                    user_email: formData.email,
                    user_code: formData.vendorCode,
                    binding_ip: formData.ipBinding,
                };
    
                // Only include printer_id for vendor type users
                const updateData = userData?.userType === 'vendor' 
                    ? { ...baseUpdateData, printerShortCode: formData.printerShortCode }
                    : baseUpdateData;
    
                const response = await updateUserStatus(updateData);
                
                if (response.status === 200 && response.data.success !== false) {
                    setSuccessMessage('User updated successfully!');
                    setShowSuccessMessage(true);
                    setTimeout(() => {
                        navigate('/user-management');
                    }, 2000);
                } else {
                    handleApiError(response);
                }
            } else {
                // Create new user - keeping the existing structure for creation
                const signUpData = {
                    userMobile: formData.mobileNumber,
                    userType: formData.userRole.toLowerCase(),
                    userFullName: formData.fullName,
                    userEmail: formData.email,
                    bindingip: formData.ipBinding,
                    ...(formData.userRole === 'vendor' && {
                        userCode: formData.vendorCode,
                        printerShortCode: formData.printerShortCode,
                        pan: formData.pan,
                        gstin: formData.gstin,
                        address: formData.address
                    })
                };
    
                const response = await userSignUp(signUpData);
    
                if (response.status === 200 && response.data.success !== false) {
                    setSuccessMessage('User created successfully!');
                    setShowSuccessMessage(true);
                    setTimeout(() => {
                        navigate('/user-management');
                    }, 2000);
                } else {
                    handleApiError(response);
                }
            }
        } catch (err: any) {
            handleSubmissionError(err);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleApiError = (response: any) => {
        const errorMessage = response.data.message;
        setError(errorMessage);
        updateValidationErrors(errorMessage);
    };

    const handleSubmissionError = (err: any) => {
        const errorMessage = err.response?.data?.message || err.message || 'An unexpected error occurred. Please try again.';
        setError(errorMessage);
        updateValidationErrors(errorMessage);
        console.error('Error submitting form:', err);
    };

    const updateValidationErrors = (errorMessage: string) => {
        const newValidationErrors: ValidationErrors = {};
        
        if (errorMessage.includes('mobile')) {
            newValidationErrors.mobileNumber = 'Mobile number already exists';
        }
        if (errorMessage.includes('email')) {
            newValidationErrors.email = 'Email already exists';
        }
        if (errorMessage.includes('vendor code') || errorMessage.includes('userCode')) {
            newValidationErrors.vendorCode = 'Vendor code already exists';
        }

        setValidationErrors(newValidationErrors);
    };

    const isFormValid = (): boolean => {
        if (isEditMode) {
            // In edit mode, button should always be enabled
            return true;
        }

        const requiredFields = ['fullName', 'userRole', 'email', 'mobileNumber', 'ipBinding'];
        const basicFieldsValid = requiredFields.every(field => formData[field as keyof FormData] !== '');

        if (formData.userRole === 'vendor') {
            return basicFieldsValid &&
                !!formData.vendorCode &&
                !!formData.printerShortCode &&
                !!formData.pan &&
                !!formData.gstin &&
                !!formData.address;
        }

        return basicFieldsValid;
    };

    const RequiredField: React.FC<{ label: string }> = ({ label }) => (
        <div className="input-label">
            {label} <span className="required-asterisk">*</span>
        </div>
    );

    const inputStyles = {
        height: '6vh',
        backgroundColor: 'white',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    };

    const selectStyles = {
        height: '6vh',
        backgroundColor: 'white',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#e5e5e5'
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#a6a6a6'
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3c3c3c'
        },
        '& .MuiSelect-select': {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontSize: '14px',
            padding: '8px 14px'
        }
    };

    return (
        <div className="form-container">
            <Header />
            <div className="form-content">
                <div className="breadcrumb-container">
                    <Breadcrumbs aria-label="breadcrumb" separator="›">
                        <Link
                            color="inherit"
                            onClick={() => navigate('/user-management')}
                            sx={{
                                cursor: 'pointer',
                                textDecoration: 'none',
                                color: 'black',
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }}
                        >
                            User Management
                        </Link>
                        <Typography color="text.primary" sx={{ color: 'black' }}>
                            {isEditMode ? 'Edit User' : 'Create User'}
                        </Typography>
                    </Breadcrumbs>
                </div>
    
                {error && (
                    <div className="error-message" style={{ color: 'red', marginBottom: '1rem' }}>
                        {error}
                    </div>
                )}
    
                <div className="form-row personal-info-row">
                    <div className="input-group">
                        <RequiredField label="Full Name" />
                        <Input
                            placeholder="Enter Full Name"
                            value={formData.fullName}
                            onChange={(value) => handleInputChange('fullName', value)}
                            className="custom-input"
                            style={inputStyles}
                        />
                    </div>
                    
                    <div className="input-group">
                        <RequiredField label="User Role" />
                        {isEditMode ? (
                            <Input
                                value={formData.userRole.charAt(0).toUpperCase() + formData.userRole.slice(1)}
                                className="custom-input"
                                style={inputStyles}
                                disabled
                            />
                        ) : (
                            <Select
                                value={formData.userRole}
                                onChange={(e) => handleInputChange('userRole', e.target.value)}
                                displayEmpty
                                className="mui-select"
                                sx={selectStyles}
                            >
                                <MenuItem value="" disabled>
                                    <span style={{ fontStyle: 'normal' }}>Select Role</span>
                                </MenuItem>
                                {roleData.map((role) => (
                                    <MenuItem key={role.value} value={role.value}>
                                        {role.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    </div>
    
                    <div className="input-group">
                        <RequiredField label="Email Address" />
                        <Input
                            type="email"
                            placeholder="example@gmail.com"
                            value={formData.email}
                            onChange={(value) => handleInputChange('email', value)}
                            className={`custom-input ${validationErrors.email ? 'error' : ''}`}
                            style={inputStyles}
                        />
                        {validationErrors.email && (
                            <div className="validation-error">{validationErrors.email}</div>
                        )}
                    </div>
                </div>
    
                <div className="form-row contact-info-row">
                    <div className="input-group">
                        <RequiredField label="Mobile Number" />
                        <Input
                            placeholder="Enter Mobile Number"
                            value={formData.mobileNumber}
                            onChange={(value) => handleInputChange('mobileNumber', value)}
                            className={`custom-input ${validationErrors.mobileNumber ? 'error' : ''}`}
                            style={inputStyles}
                            disabled={isEditMode}
                        />
                        {validationErrors.mobileNumber && (
                            <div className="validation-error">{validationErrors.mobileNumber}</div>
                        )}
                    </div>
    
                    <div className="input-group">
                        <RequiredField label="IP Binding" />
                        <Input
                            placeholder="Enter IP Binding"
                            value={formData.ipBinding}
                            onChange={(value) => handleInputChange('ipBinding', value)}
                            className="custom-input"
                            style={inputStyles}
                        />
                    </div>
    
                    {formData.userRole === 'vendor' && (
                        <div className="input-group">
                            <RequiredField label="Vendor Code" />
                            <Input
                                placeholder="Enter Vendor Code"
                                value={formData.vendorCode}
                                onChange={(value) => handleInputChange('vendorCode', value)}
                                className={`custom-input ${validationErrors.vendorCode ? 'error' : ''}`}
                                style={inputStyles}
                            />
                            {validationErrors.vendorCode && (
                                <div className="validation-error">{validationErrors.vendorCode}</div>
                            )}
                        </div>
                    )}
                </div>
    
                {formData.userRole === 'vendor' && (
                    <>
                        <div className="form-row status-info-row">
                            <div className="input-group">
                                <RequiredField label="Printer" />
                                <Input
                                        as="textarea"
                                        placeholder="Enter Printer Short Code"
                                        value={formData.printerShortCode}
                                        onChange={(value) => handleInputChange('printerShortCode', value.toUpperCase())}
                                        className="custom-input"
                                        style={{ ...inputStyles, height: '6vh' }}
                                />
                                {/* <Select
                                    value={formData.printerId || ''}
                                    onChange={(e) => handleInputChange('printerId', e.target.value)}
                                    displayEmpty
                                    className="mui-select"
                                    sx={selectStyles}
                                >
                                    <MenuItem value="" disabled>
                                        <span style={{ fontStyle: 'normal' }}>Select Printer</span>
                                    </MenuItem>
                                    {printers.map((printer) => (
                                        <MenuItem key={printer.id} value={printer.id.toString()}>
                                            {printer.printer_short_code}
                                        </MenuItem>
                                    ))}
                                </Select> */}
                            </div>
    
                            {!isEditMode && (
                                <>
                                    <div className="input-group">
                                        <RequiredField label="PAN" />
                                        <Input
                                            placeholder="Enter PAN Number"
                                            value={formData.pan}
                                            onChange={(value) => handleInputChange('pan', value)}
                                            className={`custom-input ${validationErrors.pan ? 'error' : ''}`}
                                            style={inputStyles}
                                        />
                                        {validationErrors.pan && (
                                            <div className="validation-error">{validationErrors.pan}</div>
                                        )}
                                    </div>
                                    <div className="input-group">
                                        <RequiredField label="GSTIN" />
                                        <Input
                                            placeholder="Enter GSTIN"
                                            value={formData.gstin}
                                            onChange={(value) => handleInputChange('gstin', value)}
                                            className={`custom-input ${validationErrors.gstin ? 'error' : ''}`}
                                            style={inputStyles}
                                        />
                                        {validationErrors.gstin && (
                                            <div className="validation-error">{validationErrors.gstin}</div>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
    
                        {!isEditMode && (
                            <div className="form-row status-info-row">
                                <div className="input-group">
                                    <RequiredField label="Address" />
                                    <Input
                                        as="textarea"
                                        placeholder="Enter Address"
                                        value={formData.address}
                                        onChange={(value) => handleInputChange('address', value)}
                                        className="custom-input"
                                        style={{ ...inputStyles, height: '12vh' }}
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}
    
                <div className="button-container">
                    <button
                        className={`submit-button ${(!isFormValid() || isSubmitting) ? 'disabled' : ''}`}
                        onClick={handleSubmit}
                        disabled={!isFormValid() || isSubmitting}
                    >
                        {isSubmitting ? (isEditMode ? 'Updating...' : 'Creating User...') : (isEditMode ? 'Update Details' : 'Save Details')}
                    </button>
                </div>
    
                <Snackbar
                    open={showSuccessMessage}
                    autoHideDuration={2000}
                    onClose={() => setShowSuccessMessage(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Alert severity="success" sx={{ width: '100%' }}>
                        {successMessage}
                    </Alert>
                </Snackbar>
            </div>
        </div>
    );
};

export default CreateUser;