import React, { useState, useEffect } from 'react';
import { Button, Typography, Snackbar, Alert, Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import { useParams, Link } from 'react-router-dom';
import { CheckCircle, XCircle, CheckCircle2 } from 'lucide-react';
import { toast, ToastContainer } from 'react-toastify';
import { Close } from '@mui/icons-material';
import 'react-toastify/dist/ReactToastify.css';
import CustomTable, { ColumnConfig } from "../../components/CustomTable";
import Header from "../dashboard/components/Header";
import CameraCapture from './components/CameraCapture';
import {
    fetchPurchaseOrderDetails,
    generateQrCodes,
    raiseRequestForPoItem,
    approveRequestForPoItem,
    fetchCameraPhoto,
    rejectRequestForPoItem,
} from '../../utils/services/apiService';
import './PurchaseOrderDetails.css';
import { useGeoContext } from '../../components/GeoContext';
const PurchaseOrderDetails = () => {
    const { id } = useParams();
    const userType = sessionStorage.getItem('userType');
    const isAdmin = userType === 'admin';
    const { permissionStatus } = useGeoContext();


    interface DataItem {
        id: number;
        serial_number: string;
        purchase_order_numer: string;
        purchase_order_date: string;
        item_code: string;
        description: string;
        qr_size: string;
        qr_type: string;
        quantity: number;
        total_qrs_generated: number;
        requestStatus?: string;
        request_raised?: string;
        geoLocation?: string[]; 
        created_at?: string; 
        expiryDate?: string; 
        grnQuantity?: number; 
    }

    const [data, setData] = useState<DataItem[]>([]);
    const [loading, setLoading] = useState(true);
    const [generatingQr, setGeneratingQr] = useState(false);
    const [requestingAccess, setRequestingAccess] = useState<string | null>(null);
    const [processingAction, setProcessingAction] = useState<string | null>(null);
    const [showCamera, setShowCamera] = useState(false);
    const [imageViewerOpen, setImageViewerOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState<string | null>(null);
const [currentItem, setCurrentItem] = useState<{itemCode: string, quantity: number} | null>(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success' as 'success' | 'error'
    });

    useEffect(() => {
        fetchPurchaseOrderData();
    }, [id]);


    const formatDate = (dateString?: string): string => {
        if (!dateString) return 'Not generated';
        
        try {
            const date = new Date(dateString);
            return date.toLocaleString('en-GB', {
                day: '2-digit',
                month: 'short',
                year: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            }).replace(',', ' ');
        } catch (error) {
            return 'Invalid date';
        }
    };

    const formatGeoLocation = (locations?: string[]): string => {
        if (!locations || locations.length < 2) return 'N/A';
        return `${locations[0]}, ${locations[1]}`;
    };

    const handleViewImage = async (purchaseOrderNumber: string, itemCode: string) => {
        try {
            const response = await fetchCameraPhoto({
                purchase_order_number: purchaseOrderNumber,
                item_code_number: itemCode
            });

            if (response.status === 200 && response.data.data.presigned_url) {
                const { presignedUrl } = response.data.data.presigned_url;
                setCurrentImage(presignedUrl);
                setImageViewerOpen(true);
            } else {
                toast.error('No image available for this item');
            }
        } catch (error) {
            console.error('Error fetching image:', error);
            toast.error('Failed to fetch image');
        }
    };

    const handleCloseImageViewer = () => {
        setImageViewerOpen(false);
        setCurrentImage(null);
    };


    const fetchPurchaseOrderData = async () => {
        try {
            if (!id) return;
            const response = await fetchPurchaseOrderDetails(id);
            if (response.status === 200) {
                setData(response.data.purchaseOrdersItemDetails);
            } else {
                showSnackbar('Error fetching purchase order details', 'error');
            }
        } catch (error) {
            console.error('API error:', error);
            showSnackbar('Error fetching purchase order details', 'error');
        } finally {
            setLoading(false);
        }
    };

    const showSnackbar = (message: string, severity: 'success' | 'error') => {
        setSnackbar({
            open: true,
            message,
            severity
        });
    };

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    const checkCameraPermissions = async (): Promise<boolean> => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const cameras = devices.filter(device => device.kind === 'videoinput');
            
            // If no cameras are found
            if (cameras.length === 0) {
                toast.error('No camera detected on your device.', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                return false;
            }
    
            // Try to get camera permissions
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            stream.getTracks().forEach(track => track.stop()); // Stop the stream immediately
            return true;
        } catch (error) {
            toast.error('Please enable camera access to proceed with download.', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            return false;
        }
    };


    const handleGenerateClick = async (itemCode: string, quantity: number) => {
        if (permissionStatus !== 'granted') {
            toast.error('Please enable location services to Download QR.', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            return;
        }
    
        // Check camera permissions before opening camera
        const hasCameraPermission = await checkCameraPermissions();
        if (hasCameraPermission) {
            setCurrentItem({ itemCode, quantity });
            setShowCamera(true);
        }
    };

    const handleImageCapture = async (imageBlob: Blob) => {
        if (!currentItem || !id) return;
        
        try {
            const formData = new FormData();
            formData.append('purchaseOrderNumber', id);
            formData.append('itemCode', currentItem.itemCode);
            formData.append('quantity', currentItem.quantity.toString());
            formData.append('qrFor', 'E');
            formData.append('image_buffer', imageBlob, 'capture.jpg');
            
            // Add geolocation data if available
            const latitude = sessionStorage.getItem('latitude');
            const longitude = sessionStorage.getItem('longitude');
            if (latitude && longitude) {
                formData.append('latitude', latitude);
                formData.append('longitude', longitude);
            }
    
            const response = await generateQrCodes(formData);
    
            if (response.status === 200) {
                toast.success('QR codes generation request has been processed. You will receive an email shortly.');
                setData(prevData => prevData.map(item =>
                    item.item_code === currentItem.itemCode
                        ? { ...item, total_qrs_generated: response.data.generatedQuantity || item.total_qrs_generated }
                        : item
                ));
            } else {
                toast.error('Error processing your request. Please try again later.');
            }
        } catch (error) {
            console.error('Error processing image:', error);
            toast.error('Error processing your request. Please try again later.');
        } finally {
            handleCameraClose();
        }
    };

    const handleCameraClose = () => {
        setShowCamera(false);
        setCurrentItem(null);
    };

    const handleRequestAccess = async (itemCode: string) => {
        if (!id) {
            showSnackbar('Purchase order number is required', 'error');
            return;
        }

        setRequestingAccess(itemCode);
        try {
            const response = await raiseRequestForPoItem({
                purchase_order_number: id,
                item_code_number: itemCode
            });

            if (response.status === 200) {
                toast.success('Access request submitted successfully');
                setData(prevData => prevData.map(item =>
                    item.item_code === itemCode
                        ? { ...item, requestStatus: 'pending' }
                        : item
                ));
            } else {
                showSnackbar(response.data?.message || 'Error submitting access request', 'error');
            }
        } catch (error: any) {
            console.error('API error:', error);
            showSnackbar(error.response?.data?.message || 'Error submitting access request', 'error');
        } finally {
            setRequestingAccess(null);
        }
    };

    const handleApprove = async (itemCode: string) => {
        if (!id) return;
        setProcessingAction(itemCode);
        try {
            const response = await approveRequestForPoItem({
                purchase_order_number: id,
                item_code_number: itemCode
            });

            if (response.status === 200) {
                toast.success('Request approved successfully');
                setData(prevData => prevData.map(item =>
                    item.item_code === itemCode
                        ? { ...item, requestStatus: 'approved' }
                        : item
                ));
            } else {
                toast.error('Error approving request');
            }
        } catch (error: any) {
            console.error('API error:', error);
            showSnackbar(error.response?.data?.message || 'Error approving request', 'error');
        } finally {
            setProcessingAction(null);
        }
    };

    const handleReject = async (itemCode: string) => {
        if (!id) return;
        setProcessingAction(itemCode);
        try {
            const response = await rejectRequestForPoItem({
                purchase_order_number: id,
                item_code_number: itemCode
            });
    
            if (response.status === 200) {
                toast.success('Request rejected successfully');
                setData(prevData => prevData.map(item =>
                    item.item_code === itemCode
                        ? { ...item, requestStatus: 'rejected' }
                        : item
                ));
            } else {
                toast.error('Error rejecting request');
            }
        } catch (error: any) {
            console.error('API error:', error);
            showSnackbar(error.response?.data?.message || 'Error rejecting request', 'error');
        } finally {
            setProcessingAction(null);
        }
    };

    const columns: ColumnConfig[] = [
        // {
        //     field: 'id',
        //     headerName: 'Sr No.',
        //     width: 80,
        //     editable: false,
        //     headerClassName: 'bold-header',
        // },
        {
            field: 'serial_number',
            headerName: 'Serial Number + Line item',
            width: 200,
            editable: false,
            headerClassName: 'bold-header',
        },
        {
            field: 'purchase_order_numer',
            headerName: 'PO Number',
            width: 110,
            editable: false,
            headerClassName: 'bold-header',
        },
        {
            field: 'purchase_order_date',
            headerName: 'PO Date',
            width: 110,
            editable: false,
            headerClassName: 'bold-header',
        },
        {
            field: 'item_code',
            headerName: 'Item Code',
            width: 155,
            editable: false,
            headerClassName: 'bold-header',
        },
        {
            field: 'description',
            headerName: 'Item Description',
            width: 280,
            editable: false,
            headerClassName: 'bold-header',
        },
        {
            field: 'qr_size',
            headerName: 'QR Size',
            width: 100,
            editable: false,
            headerClassName: 'bold-header',
        },
        {
            field: 'qr_type',
            headerName: 'QR Type',
            width: 100,
            editable: false,
            headerClassName: 'bold-header',
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            width: 110,
            editable: false,
            headerClassName: 'bold-header',
        },
        {
            field: 'total_qrs_generated',
            headerName: 'Generated Qty.',
            width: 140,
            editable: false,
            headerClassName: 'bold-header',
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 145,
            renderCell: (params: any) => {
                const isFullyGenerated = params.row.total_qrs_generated >= params.row.quantity;
                const isGeoEnabled = permissionStatus === 'granted';

                if (!isAdmin && isFullyGenerated) {
                    return (
                        <Button
                            variant="contained"
                            className="action-button generate-button"
                            disabled={true}
                            style={{ backgroundColor: '#e0e0e0', color: '#666' }}
                        >
                            Downloaded
                        </Button>
                    );
                }

                return (
                    <Button
                        variant="contained"
                        className="action-button generate-button"
                        onClick={() => handleGenerateClick(
                            params.row.item_code,
                            params.row.quantity - params.row.total_qrs_generated
                        )}
                        disabled={generatingQr || (!isAdmin && isFullyGenerated)}
                        style={!isGeoEnabled ? {
                            cursor: 'pointer',
                            opacity: 0.8
                        } : {}}
                    >
                        {generatingQr ? 'Downloading' : 'Download '}
                    </Button>
                );
            },
            headerClassName: 'bold-header',
        },
        {
            field: 'access_request',
            headerName: 'Request Access',
            width: 220,
            renderCell: (params: any) => {
                const isFullyGenerated = params.row.total_qrs_generated >= params.row.quantity;
        
                if (!isFullyGenerated) {
                    return null;
                }
        
                if (params.row.requestStatus === 'approved' || params.row.requestStatus === 'rejected') {
                    return (
                        <Typography variant="body2" color="textSecondary">
                            {params.row.requestStatus === 'approved' ? 'Request Approved' : 'Request Rejected'}
                        </Typography>
                    );
                }
        
                return (
                    <div className="action-buttons">
                        {/* {userType === 'admin' && params.row.request_raised === "true" && ( */}
                        {userType === 'admin' && params.row.request_raised !== "false" && (
                            <>
                                <Button
                                    variant="contained"
                                    className="action-button approve-button"
                                    onClick={() => handleApprove(params.row.item_code)}
                                    disabled={processingAction === params.row.item_code}
                                    startIcon={<CheckCircle size={16} />}
                                >
                                    {processingAction === params.row.item_code ? 'Processing' : 'Approve'}
                                </Button>
                                <Button
                                    variant="contained"
                                    className="action-button reject-button"
                                    onClick={() => handleReject(params.row.item_code)}
                                    disabled={processingAction === params.row.item_code}
                                    startIcon={<XCircle size={16} />}
                                >
                                    Reject
                                </Button>
                            </>
                        )}
                        {userType !== 'admin' && (
                            <Button
                                variant="contained"
                                className="action-button request-access-button"
                                onClick={() => handleRequestAccess(params.row.item_code)}
                                disabled={requestingAccess === params.row.item_code || params.row.requestStatus === 'pending'}
                                style={{
                                    width: '10px',
                                    minWidth: '40px',
                                    padding: '0px',
                                }}
                            >
                                {requestingAccess === params.row.item_code ? (
                                    <span className="loading-spinner" />
                                ) : params.row.requestStatus === 'pending' ? (
                                    <CheckCircle2 size={20} style={{ opacity: 0.5 }} />
                                ) : (
                                    <CheckCircle2 size={20} />
                                )}
                            </Button>
                        )}
                    </div>
                );
            },
            headerClassName: 'bold-header',
        },
        ...(userType === 'admin'
            ? [
                {
                    field: 'view_image',
                    headerName: 'Image',
                    width: 150,
                    renderCell: (params: any) => (
                        params.row.total_qrs_generated > 0 ? (
                            <Button 
                                variant="outlined" 
                                color="primary"
                                onClick={() => handleViewImage(params.row.purchase_order_numer, params.row.item_code)}
                            >
                                View Image
                            </Button>
                        ) : null
                    ),
                    headerClassName: 'bold-header',
                },
                {
                    field: 'geo_location',
                    headerName: 'Geo Location',
                    width: 180,
                    editable: false,
                    headerClassName: 'bold-header',
                    renderCell: (params: any) => (
                        formatGeoLocation(params.row.geoLocation)
                    )
                },
            ]
            : []),
            {
                field: 'generation_status',
                headerName: 'Generation Status',
                width: 280,
                editable: false,
                headerClassName: 'bold-header',
                renderCell: (params: any) => (
                    params.row.created_at 
                        ? `QR generated on ${formatDate(params.row.created_at)}` 
                        : 'Not yet generated'
                )
            },
            {
                field: 'expiry_date',
                headerName: 'Expiry Date',
                width: 170,
                editable: false,
                headerClassName: 'bold-header',
                renderCell: (params: any) => (
                    params.row.expiryDate 
                        ? formatDate(params.row.expiryDate) 
                        : 'No expiry date'
                )
            },
            {
                field: 'grn_qty',
                headerName: 'GRN Qty.',
                width: 90,
                editable: false,
                headerClassName: 'bold-header',
                renderCell: (params: any) => (
                    `${params.row.grnQuantity ?? 0}`
                )
            },
            {
                field: 'plant',
                headerName: 'plant',
                width: 100,
                editable: false,
                headerClassName: 'bold-header',
            },
    ];

    return (
        <div className='screen'>
            <Header />
            <ToastContainer
                position="top-center"
                autoClose={4000} />
            <div className='table'>
                <br />
                <Typography variant="body1" className="breadcrumb">
                    <Link to="/purchaseorder" className="breadcrumb-link">Purchase Order</Link> &gt;
                    <span className="breadcrumb-current">Purchase Order Details</span>
                </Typography>
                <br />
                {showCamera && (
            <CameraCapture
                onImageCapture={handleImageCapture}
                onClose={handleCameraClose}
            />
        )}
                
                <CustomTable
                    editMode={'row'}
                    data={data}
                    columns={columns}
                    loading={loading}
                    getRowId={(data: any) => data.item_code}
                />
            </div>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Dialog 
                    open={imageViewerOpen} 
                    onClose={handleCloseImageViewer}
                    maxWidth="md"
                    fullWidth
                >
                    <DialogTitle>
                        Captured Image
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseImageViewer}
                            sx={{
                                position: 'absolute',
                                right: 18,
                                top: 20,
                                color: 'red',
                            }}
                        >
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {currentImage && (
                            <img 
                                src={currentImage} 
                                alt="Captured" 
                                style={{ 
                                    width: '100%', 
                                    maxHeight: '500px', 
                                    objectFit: 'contain' 
                                }} 
                            />
                        )}
                        <Button 
                            onClick={() => window.open(currentImage!, '_blank')} 
                            variant="contained" 
                            color="primary"
                            style={{ marginTop: '10px', marginLeft:'50vh' }}
                        >
                            Download Image
                        </Button>
                    </DialogContent>
                </Dialog>
        </div>
    );
};
export default PurchaseOrderDetails;